import { LoaderIcon } from '@mentimeter/ragnar-visuals';
import { Box, Button, Text } from '@mentimeter/ragnar-ui';

export const LoadingOrError = ({ error }: { error?: string }) => (
  <Box
    alignItems="center"
    justifyContent="center"
    position="absolute"
    width="100%"
    height="100%"
  >
    {error ? (
      <>
        <Text mt="3">{error}</Text>
        <Button
          size="large"
          mt="1"
          aria-label="Reload page"
          onClick={() => window.location.reload()}
        >
          Click to reload page
        </Button>
      </>
    ) : (
      <LoaderIcon size={5} />
    )}
  </Box>
);
